import api from "../../api/fdxregistryapi";
import router from '../../router/Router';
import Toast from "../../components/Notifications/Toast"

const vueState = {
    userRole: 'anonymous',
    user: null,
    showRREPopup: false,
    showREPopup: false,
    popup: false,
    popupimis: false,
    becomemember: false,
    refreshTokenExpired: false,
    authCode: null,
    token: null,
    tokenStartTime: null,
    callbackURL:null,

}

const getters = {
    getRefreshTokenStartTime(state) {
        return state.tokenStartTime;
    },
    getUserRole(state) {
        return state.userRole;
    },
    getPopup(state) {
        return state.popup
    },
    getPopupimis(state) {
        return state.popupimis
    },
    getBecomemember(state) {
        return state.becomemember
    },
    getAuthCode(state) {
        return state.authCode
    },
    getToken(state) {
        return state.token
    },
    getCallbackURL(state) {
        return state.callbackURL
    }
}

const mutations = {
    setUserRole(state, userRole) {
        state.userRole = userRole;
    },
    setTokenStatus(state, refreshTokenExpired) {
        state.refreshTokenExpired = refreshTokenExpired;
    },
    setTokenStartTime(state, tokenStartTime) {
        state.tokenStartTime = tokenStartTime;
    },
    setShowRREPopup(state, showRREPopup) {
        state.showRREPopup = showRREPopup;
    },
    setShowREPopup(state, showREPopup) {
        state.showREPopup = showREPopup;
    },
    setUser(state, user) {
        state.user = user;
    },
    setPopup(state, popup) {
        state.popup = popup;
    },
    setPopupimis(state, popupimis) {
        state.popupimis = popupimis;
    },
    setBecomemember(state, becomemember) {
        state.becomemember = becomemember;
    },
    setAuthCode(state, authCode) {
        state.authCode = authCode;
    },
    setToken(state, token) {
        state.token = token
    },
    setCallbackURL(state,callbackURL){
        state.callbackURL = callbackURL
    }
}

const actions = {
    updateUserRole({ commit }, userRole) {
        commit('setUserRole', userRole)
    },
    updateUserInfo({ commit }, userInfo) {
        commit('setUser', userInfo);
    },
    updateToken({ commit }, token) {
        commit('setToken', token);
    },
    updateAuthcode({ commit }, authcode) {
        commit('setAuthCode', authcode);
    },
    updatePopup({ commit }, popup) {
        commit('setPopup', popup)
    },
    ssoAuthentication() {
        api.ssoAuthentication();
    },
    ssoSignout({ dispatch, rootState ,commit}) {
        commit('setUser', null);
        commit('setUserRole', "anonymous");
        commit("setSearchText","");
        commit("setSortOrder","ASC");
        commit("setPublishFilter","All");
        commit("setRoleFilter",["All Roles"]);
        commit("setCountryFilter",["all countries"]);
        commit("setFdxVersionFilter",["All Versions"]);
        commit("setMembershipFilter",["All"]);
        commit("setCurrentPaginationPage",1);
        const diff = new Date().getTime() - rootState.tokenStartTime;
        if(diff > 600000 && rootState?.token?.id_token){
            api.ssoSignout(rootState.Auth.token).then(response => {
                if (response.status == 200 ) {
                    commit('setToken', null);
                    router.push("/");
                }
            }).catch(function (error) {
                commit('setToken', null);
                router.push("/");

            });
        }
        else{
            commit('setToken', null);
                router.push("/");
        }


    },
    getAccessToken({ commit, dispatch }, token) {
                commit('setTokenStartTime', new Date().getTime());
                commit('setToken',JSON.stringify( token));
                commit('setTokenStatus', true);
                dispatch('getUserInfo');
    },

    async refreshToken({ commit, dispatch, rootState }, request) {
        await api.refreshToken(rootState.Auth.token).then(response => {
            if (response?.status == 200 && response?.data) {
                commit('setTokenStartTime', new Date().getTime());
                commit('setTokenStatus', true);
                commit('setToken', JSON.stringify(response.data));
            }
        }).catch(function (error) {
            commit('setTokenStatus', false);
            commit("setShowLoadingOnApiCall", false);
            if (error?.response) {
                if (error?.response?.status === 400) {
                    router.push("/logout");
                }
            }
        })
    },

    getUserInfo({ commit, dispatch, rootState }) {
        api.getUserInfo(JSON.parse(rootState.Auth.token).access_token).then(response => {
            if (response?.status === 200 && response?.data) {
                if (response?.data?.userLogo) {
                    response.data.userLogo = "data:image/png;base64," + response.data.userLogo;
                }

                var user = response.data;
                user.paymentStatus = false;
                var userRole = "";
                if (response?.data?.memberType === "STAFI" || response?.data?.memberType === "S-CON") {
                    userRole = "admin";
                } else if (response?.data?.memberType === "REGIL" || response?.data?.memberType === "REGIV" ||
                    response?.data?.memberType === "CONST" || response?.data?.memberType === "CONSU" || response?.data?.memberType === "CONTI") {
                    if (response?.data?.memberType === "CONST" || response?.data?.memberType === "CONSU" || response?.data?.memberType === "CONTI") {
                        user.paymentStatus = true;
                    } else {
                        user.paymentStatus = false;
                    }
                    userRole = "member";
                } else if (response?.data?.memberType === "NCONM" || response?.data?.memberType === "NCONP") {
                    userRole = "non-member";
                    user.paymentStatus = false;
                } else {
                    userRole = "anonymous";
                    user.paymentStat.us = false;
                }
                commit('setUser', JSON.stringify(user));
                commit('setUserRole', userRole);
                dispatch('getAPIVersions', 'FDX');
                dispatch('getTaxonomyTypes');
                commit("setSortOrder", "ASC");
                commit("setPublishFilter", "All");
                commit("setRoleFilter", ["All Roles"]);
                commit("setCountryFilter", ["all countries"]);
                commit("setFdxVersionFilter", ["All Versions"]);
                commit("setMembershipFilter",["All"]);
                commit("setCurrentPaginationPage", 1);
                if(rootState?.Auth?.callbackURL){
                    router.push(rootState.Auth.callbackURL);
                }else {
                    router.push("/");
                }
            }
        }).catch(err => {
            Toast.showToast('Error occured while getting user Details from IMIS', 'E');
            router.push("/");
        })
    },

}

export default {
    state: vueState,
    getters,
    mutations,
    actions
}