<template>
  <div :disabled="bgDisabled">
    <a class="visually-hidden-focusable" href="#main-content">Skip to main content</a>

    <nav class="navbar navbar-expand-xl navbar-dark" role="navigation">
      <div class="container container-md container-sm">
        <router-link to="/" class="navbar-brand">
          <img src="@/assets/FDX900.png" alt="Financial Data Exchange logo" />
        </router-link>
        <button id="mobileMenuBtn" class="navbar-toggler" type="button" @click="toggleMobileMenu" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent" :class="{ show: showMobileMenu }">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a class="nav-link" :href="this.URL ==='https://registry.financialdataexchange.org'?'https://financialdataexchange.org/FDX/FDX/About/About-FDX.aspx?hkey=dffb9a93-fc7d-4f65-840c-f2cfbe7fe8a6' :'https://fdxdev.imiscloud.com/FDX/FDX/About/About-FDX.aspx?hkey=dffb9a93-fc7d-4f65-840c-f2cfbe7fe8a6'">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="this.URL ==='https://registry.financialdataexchange.org'?'https://financialdataexchange.org/FDX/FDX/News/FDX-News.aspx?hkey=018fdd8f-d23e-407c-8732-810ca910f57c':'https://fdxdev.imiscloud.com/FDX/FDX/News/FDX-News.aspx?hkey=018fdd8f-d23e-407c-8732-810ca910f57c'">Fdx Feed</a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" href="#">Registry</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="this.URL ==='https://registry.financialdataexchange.org'?'https://financialdataexchange.org/FDX/FDX/Events/Events_List.aspx?DisplayAreaOptions=No&hkey=304c3960-e79b-47b9-9615-37e887dae624':'https://fdxdev.imiscloud.com/FDX/FDX/Events/Events_List.aspx?DisplayAreaOptions=No&hkey=304c3960-e79b-47b9-9615-37e887dae624'">Events</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" :href="this.URL ==='https://registry.financialdataexchange.org'?'https://financialdataexchange.org/FDX/FDX/Membership/Benefits.aspx?hkey=3537db1b-f8eb-4c56-aeac-b492c89a2318':'https://fdxdev.imiscloud.com/FDX/FDX/Membership/Benefits.aspx?hkey=3537db1b-f8eb-4c56-aeac-b492c89a2318'">Get Started</a>
            </li>
            <li class="nav-item pt-1">
              <button id="btn_sign_in" v-if="this.$store.state.Auth.userRole === 'anonymous'" @click="doLogin" class="btn btn-success signin-btn " aria-label="Single Sign On">
                Sign in
              </button>

              <div class="dropdown" v-else>
                <a href="#" v-if="JSON.parse(this.$store.state.Auth.user).userLogo" class="p-2" style="margin-top:4px" id="logout-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img :src="JSON.parse(this.$store.state.Auth.user).userLogo" width="40" height="40" class="rounded-circle green-border" alt="user icon">
                  <img src="@/assets/Vector.png" class="arrow-img" alt="">
                </a>
                <a href="#" v-else class="p-2" style="margin-top:4px" aria-label="Logout" id="logout-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <em class="bi bi-person-circle icon-style" alt="user icon"></em>
                  <img src="@/assets/Vector.png" class="arrow-img" alt="">
                </a>
                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="logout-dropdown">

                  <button class="dropdown-item logout-btn" @click="doLogout" >Logout</button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      showMobileMenu: false,
      bgDisabled: false,
      URL:window.location.origin,
    };
  },
  created() {
    this.getEnumTypes();
    this.getCountries();
    this.getListingTags();
    // this.getOrganizationsList();
  },
  methods: {
    ...mapMutations(["setUserRole", "setUser","setCallbackURL","setPopup"]),
    ...mapActions([
      "updateUserInfo",
      "updateToken",
      "getCountries",
      "getEnumTypes",
      "getListingTags",
      "getOrganizationsList"
    ]),
    popup(popup) {
      this.setPopup(popup);
    },
    doLogin() {
      this.setCallbackURL(this.$route.fullPath);
      this.$router.push("/login");
    },
    doLogout() {
      this.setCallbackURL(this.$route.fullPath);
      this.$router.push("/logout");
    },

    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    disablebg(value) {
      this.bgDisabled = value;
    },
  },
};
</script>
<style scoped>
.logout-btn {
  font-size: 14px;
}
.dropdown-item:focus {
  color: #ffffff;
  background-color: #009382 !important;
}
.dropdown-item:hover {
  color: #ffffff;
  background-color: #009382 !important;
}
#mobileMenuBtn {
  color: #ffffff;
  background-color: #009382;
}
.signin-btn {
  background-color: #0f8575;
}
.signin-btn:hover {
  background: #0e7568;
  color: #ffffff !important ;
  border: 1px solid #0e7568;
}
.nav-link {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #314959 !important;
  padding: 10px 0;
  text-transform: uppercase !important;
  font-weight: 500;
}
.nav-item,
.dropdown {
  padding: 10px 0;
}
#btn_sign_in {
  margin-top: 10px;
  text-transform: uppercase !important;
  font-weight: 500;
}
.active {
  color: #09645f !important;
}
.nav-item a.active {
  border-bottom: 3px solid #0f7c75;
}
.pt-1 {
  padding-top: 0px !important ;
}
.usericon {
  margin-top: 7px;
}
@media (max-width: 1674px) {
  .nav-item a.active {
    width: fit-content;
  }
}
@media (max-width: 1200px) {
  .dropdown-menu.show {
    display: table-cell;
  }
}
.arrow-img {
  padding: 5px;
}
.green-border {
  border: 2px solid #0f8575;
}
.icon-style {
  font-size: 25px;
  color: #0f8575;
}
</style>
