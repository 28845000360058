import api from "../../api/fdxregistryapi";
import Toast from "../../components/Notifications/Toast"
import router from '../../router/Router';

const vueState = {
    showLoadingOnApiCall: false,
    filePath: null,
    companyList: [],
    companyData: [],
    currentCompanyDetail: [],
    countries: null,
    enumTypes: null,
    versionHistoryCompanyData: null,
    currentCompanyData: null,
    publishFilter: "All",
    searchText: "",
    selectedOption:null,
    options:[],
    sortOrder: "ASC",
    roleFilter: ["All Roles"],
    membershipFilter: ["All"],
    countryFilter: ["all countries"],
    fdxVersionFilter: ["All Versions"],
    currentPage: 1,
    versionHistoryList: null,
    ImisSearchResult: null,
    aPIVersions: null,
    isOrgCreated: false,
    fdxVersionLookup: null,
    ddaVersions:null,
    ukVersions:null,
    proprietaryVersion:null,
    ofxVersions:null,
    printPageTo: 1,
    printPageFrom: 1,
    taxonomyTypes: null,
    tagsList:[],
    listingReqTags:null,
    currPage: 0,
    totalElements: 0,
    totalPages: 0

}

const getters = {
    getCompanyDataList(state) {
        return state.companyList;
    },
    getCompanyData(state) {
        return state.companyData;
    }
}
const defaultLogo = 'https://storage.googleapis.com/companylogos/default-logo.png';

const mutations = {
    setFilePath(state, filePath) {
        state.filePath = filePath;
    },
    setShowLoadingOnApiCall(state, showLoadingOnApiCall) {
        state.showLoadingOnApiCall = showLoadingOnApiCall;
    },
    setTaxonomyTypes(state, taxonomyTypes) {
        state.taxonomyTypes = taxonomyTypes;
    },
    setCountries(state, countries) {
        state.countries = countries;
    },
    setPrintPageTo(state, printPageTo) {
        state.printPageTo = printPageTo;
    },
    setPrintPageFrom(state, printPageFrom) {
        state.printPageFrom = printPageFrom;
    },
    setFdxVersionLookup(state, fdxVersionLookup) {
        state.fdxVersionLookup = fdxVersionLookup;
    },
    setIsOrgCreated(state, isOrgCreated) {
        state.isOrgCreated = isOrgCreated;
    },
    setAPIVersions(state, aPIVersions) {
        state.aPIVersions = aPIVersions;
    },
    setDDAVersions(state, aPIVersions) {
        state.ddaVersions = aPIVersions;
    },
    setOFXVersions(state, aPIVersions) {
        state.ofxVersions = aPIVersions;
    },
    setUk(state, aPIVersions) {
        state.ukVersions = aPIVersions;
    },
    setProprietaryVersion(state, aPIVersions) {
        state.proprietaryVersion = aPIVersions;
    },
    setImisSearchResult(state, ImisSearchResult) {
        state.ImisSearchResult = ImisSearchResult;
    },
    setVersionHistoryList(state, versionHistoryList) {
        state.versionHistoryList = versionHistoryList;
    },
    setRestoreVersionHistory(state, restoreVersionHistory) {
        state.restoreVersionHistory = restoreVersionHistory;
    },
    setEnumTypes(state, enumTypes) {
        state.enumTypes = enumTypes;
    },
    setCompanyDataList(state, companyList) {
        state.companyList = companyList;
    },
    setCompanyDataListCurrPage(state, currPage) {
        state.currPage = currPage;
    },
    setCompanyDataListTotalElements(state, totalElements) {
        state.totalElements = totalElements;
    },
    setCompanyDataListTotalPages(state, totalPages) {
        state.totalPages = totalPages;
    },
    setCompanyData(state, companyData) {
        state.companyData = companyData;
    },
    setVersionHistoryCompanyData(state, versionHistoryCompanyData) {
        state.versionHistoryCompanyData = versionHistoryCompanyData;
    },
    setCurrentCompanyData(state, currentCompanyData) {
        state.currentCompanyData = currentCompanyData;
    },
    setPublishFilter(state, publishFilter) {
        state.publishFilter = publishFilter;
    },
    setSearchText(state, searchText) {
        state.searchText = searchText;
    },
    setSortOrder(state, sortOrder) {
        state.sortOrder = sortOrder;
    },
    setRoleFilter(state, roleFilter) {
        state.roleFilter = roleFilter;
    },
    setCountryFilter(state, countryFilter) {
        state.countryFilter = countryFilter;
    },
    setMembershipFilter(state, membershipFilter) {
        state.membershipFilter = membershipFilter;
    },
    setFdxVersionFilter(state, fdxVersionFilter) {
        state.fdxVersionFilter = fdxVersionFilter;
    },
    setCurrentPaginationPage(state, currentPage) {
        state.currentPage = currentPage;
    },
    setCurrentCompanyDetail(state, currentCompanyDetail) {
        state.currentCompanyDetail = currentCompanyDetail;
    },
    setTagsList(state, tagsList) {
        state.tagsList = tagsList;
    },
    setOptions(state, options) {
        state.options = options;
    },
    setSelectedOption(state, tag) {
        state.selectedOption = tag;
    },
    setListingReqTags(state, listingReq) {
        state.listingReqTags = listingReq;
    },
}
function sortObjectByKeys(o) {
    return Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {});
}

function sortCountries(countries) {
    return countries.sort(function (a, b) { return a.name.localeCompare(b.name); });
}


function orgTypeKeyToVal(enumTypes, val) {
    var enums = JSON.parse(enumTypes).organizationTypes;
    Object.keys(enums).forEach(function (key) {
        if (key === val) {
            val = enums[key].toUpperCase();
        }
    });
    return val;
}

function membershipTypeKeyToValue(enumTypes, vall) {
    var memberTypes = JSON.parse(enumTypes).membershipTypes;
    Object.keys(memberTypes).forEach(function (key) {
        if (key === vall) {
            vall = memberTypes[key].toUpperCase();
        }
    });
    return vall;
}
const actions = {

    async uploadImage({ commit }, request) {
        commit("setShowLoadingOnApiCall", true);

        await api.uploadImage(request).then(response => {
            if (response.status == 200 && response.data) {
                commit("setShowLoadingOnApiCall", false);
                commit('setFilePath', response.data);
            }
        }).catch(function (error) {
            commit("setShowLoadingOnApiCall", false);
        })

    },


    async getOrganizations({ commit, rootState }) {
        commit('setCompanyDataList', null);
        commit('setShowRREPopup', false);
        commit('setShowREPopup', false);



        await api.getOrganizations().then(response => {
            if (response.status === 200 && response.data.length > 0) {
                for (var i = 0; i < response.data.length; i++) {
                    if (response.data[i].logoLocation === "" || response.data[i].logoLocation === null) {
                        response.data[i].logoLocation = defaultLogo;
                    }
                    response.data[i].organizationType = orgTypeKeyToVal(rootState.Company.enumTypes, response.data[i].organizationType);

                    if (JSON.parse(rootState.Auth.user) && response.data[i].imisNumber && JSON.parse(rootState.Auth.user).companyId) {
                        if (response.data[i].imisNumber.toString() === JSON.parse(rootState.Auth.user).companyId.toString()) {
                            commit('setShowRREPopup', true);
                            commit('setShowREPopup', true);
                        }
                    }
                    if (response.data[i].fdxTaxonomyTypeList) {
                        var taxonomy = [];
                        for (var ss = 0; ss < response.data[i].fdxTaxonomyTypeList.length; ss++) {
                            taxonomy.push(orgTypeKeyToVal(rootState.Company.enumTypes, response.data[i].fdxTaxonomyTypeList[ss].type));
                        }
                        response.data[i].fdxTaxonomyTypes = taxonomy;
                    }
                }
                commit('setCompanyDataList', JSON.stringify(response.data));
            }
        }).catch(function (error) {
            Toast.showToast('Request failed with status code 404', 'E');
        })
    },

    async getOrganizationDetail({ commit, rootState }, id) {
        commit('setCompanyData', null);
        commit('setListingReqTags', []);
        commit('setSearchText', '');
        await api.getOrganizationDetail(id).then(response => {
            if (response.status == 200 && response.data) {
                if (response.data.logoLocation === "" || response.data.logoLocation === null) {
                    response.data.logoLocation = defaultLogo;
                }
                response.data.organizationType = orgTypeKeyToVal(rootState.Company.enumTypes, response.data.organizationType);
                response.data.membershipType = membershipTypeKeyToValue(rootState.Company.enumTypes, response.data.membershipType);
                if (response.data.fdxTaxonomyTypeList) {
                    var taxonomy = [];
                    for (var ss = 0; ss < response.data.fdxTaxonomyTypeList.length; ss++) {
                        taxonomy.push(orgTypeKeyToVal(rootState.Company.enumTypes, response.data.fdxTaxonomyTypeList[ss].type));
                    }
                    response.data.fdxTaxonomyTypes = taxonomy;
                }
            }
            commit('setCompanyData', JSON.stringify(response.data));
            if (response.data && response.data.dataSynced !== undefined && response.data.dataSynced === false) {
                Toast.showToast('Error occured while updating Company Details from IMIS', 'E');
            }
        }).catch(function (error) {
            Toast.showToast('Request failed with status code 404', 'E');
        })
    },

    async getCountries({ commit },) {
        commit('setCountries', null);
        await api.getCountries().then(response => {
            if (response.status == 200 && response.data) {
                var sortedCountries = sortCountries(response.data);
                commit('setCountries', JSON.stringify(sortedCountries));
            }
        }).catch(function (error) {
            Toast.showToast('Request failed with status code 404', 'E');
        })
    },
    async getListingTags({ commit },) {
        commit('setTagsList', []);
        await api.getListingTags().then(response => {
            if (response.status == 200 && response.data) {
                commit('setTagsList', response.data);

            }
        }).catch(function (error) {
            Toast.showToast('Request failed with status code 404', 'E');
        })
    },
    async getEnumTypes({ commit },) {
        commit('setEnumTypes', null);
        await api.getEnumTypes().then(response => {
            if (response.status == 200 && response.data) {
                var membershipTypes = sortObjectByKeys(response.data.membershipTypes);
                var organizationTypes = sortObjectByKeys(response.data.organizationTypes);
                var sortedData = {
                    organizationTypes: organizationTypes,
                    membershipTypes: membershipTypes,
                    organizationStatus: response.data.organizationStatus,
                    productTypes: response.data.productTypes,
                    apiPackages: response.data.apiPackages,
                    versionTypes: response.data.versionTypes
                };
                commit('setEnumTypes', JSON.stringify(sortedData));
            }
        }).catch(function (error) {
            Toast.showToast('Request failed with status code 404', 'E');
        })
    },

    async createOrganization({ commit, dispatch, rootState }, request) {

        commit("setShowLoadingOnApiCall", true);
        await api.createOrganization(request).then(response => {
            if (response.status == 200 && response.data) {
                response.data.id = response.data.organizationId;
                commit("setCurrentCompanyDetail", response.data);
                commit("setShowLoadingOnApiCall", false);

                if(response.data.shortNameConflict && response.data.shortNameConflict === true){
                    Toast.showToast('Company Added Successfully, But Company short name was not saved due to conflict', 'E');
                }else{
                    Toast.showToast('Company Added Successfully', 'S');
                }

                commit("setIsOrgCreated", true);
                router.push("/companyDetail/"+response.data.imisNumber);
            }
        }).catch(function (error) {
            commit("setIsOrgCreated", false);
            commit("setShowLoadingOnApiCall", false);

        })

    },

    async requestRegistryEntry({ commit }, request) {
        await api.requestRegistryEntry(request).then(response => {
            if (response.status == 200 && response.data) {
                //
            }
        }).catch(function (error) {
            console.log(error);
        })
    },

    async requestCompanyData({ commit }, request) {
        await api.requestCompanyData(request).then(response => {
            if (response.status == 200 && response.data) {
                response.data.role = request.role;
            }
        }).catch(function (error) {
            console.log(error);
        })
    },

    async requestChange({ commit }, request) {
        await api.requestChange(request).then(response => {
            if (response.status == 200 && response.data) {
                response.data.role = request.role;
            }
        }).catch(function (error) {
            console.log(error);
        })
    },
    async getVersionHistoryById({ commit, rootState }, id) {
        commit('setVersionHistoryCompanyData', null);
        await api.getVersionHistoryById(id).then(response => {
            if (response.status == 200 && response.data) {
                var originalRecord = JSON.parse(response.data.originalRecord);

                originalRecord.organizationType = orgTypeKeyToVal(rootState.Company.enumTypes, originalRecord.organizationType);
                originalRecord.membershipType = membershipTypeKeyToValue(rootState.Company.enumTypes, originalRecord.membershipType);
                commit('setVersionHistoryCompanyData', originalRecord);
            }
        }).catch(function (error) {
            console.log(error);
        })
    },
    async publishOrganization({ commit, dispatch, rootState }, request) {
        commit("setShowLoadingOnApiCall", true);

        await api.publishOrganization(request).then(response => {
            if (response.status == 200 && response.data) {
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast('Company Updated Successfully', 'S');
                dispatch('getOrganizationDetail', response.data.imisNumber);
                dispatch('getVersionHistoryList', response.data.imisNumber);
            }
        }).catch(function (error) {
            commit("setShowLoadingOnApiCall", false);
            Toast.showToast(error?.response?.data, 'E');
        })
    },
    async companyDelete({ commit, rootState, dispatch }, request) {
        commit("setShowLoadingOnApiCall", true);
        await api.companyDelete(request.id).then(response => {
            if (response.status == 200 && response.data) {
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast('Company Deleted', 'E');
                dispatch('getOrganizationDetail', response.data.imisNumber)
            }
        }).catch(function (error) {
            commit("setShowLoadingOnApiCall", false);

        })

    },
    async createVersionHistory({ rootState, commit, dispatch }, request) {
        commit("setShowLoadingOnApiCall", true);


        if (request.directPublish) {
            var publishRequest = {
                id: request.id,
                vid: 0,
                userRole: request.role
            }
            dispatch('publishOrganization', publishRequest);
        } else {

            await api.createVersionHistory(request).then(response => {
                if (response.status == 200 && response.data) {
                    commit("setShowLoadingOnApiCall", false);
                    if (!request.publish) {
                        Toast.showToast('Company Information Updated', 'S');
                        dispatch('getOrganizationDetail', JSON.parse(rootState.Company.companyData).imisNumber);
                    } else {
                        if (request.publish) {
                            var publishRequestData = {
                                id: request.id,
                                vid: response.data.changeRequest.id,
                                userRole: request.role
                            }
                            dispatch('publishOrganization', publishRequestData);
                        }
                    }
                }
            }).catch(function (error) {
                commit("setShowLoadingOnApiCall", false);
            })

        }
    },

    async updateOrganizationByVersionHistory({ rootState, dispatch }, request) {

        await api.updateOrganizationByVersionHistory(request).then(response => {
            if (response.status == 200 && response.data) {
                Toast.showToast('Company Detail Updateded', 'S');
                dispatch('getVersionHistoryList', response.data.imisNumber);
            }
        }).catch(function (error) {
            console.log(error);
        })
    },

    async getVersionHistoryList({ commit, dispatch, rootState }, id) {

        commit('setVersionHistoryList', null);
        await api.getVersionHistoryList(id).then(response => {
            if (response.status == 200 && response.data) {
                commit('setVersionHistoryList', JSON.stringify(response.data));
            }
        }).catch(function (error) {
            console.log(error);
        })
    },

    async makeInactive({ commit, rootState, dispatch }, request) {
        commit("setShowLoadingOnApiCall", true);

        await api.makeInactive(request.id).then(response => {
            if (response.status == 200 && response.data) {
                commit("setShowLoadingOnApiCall", false);
                Toast.showToast('Company Information Updated', 'S');
                dispatch('getOrganizationDetail', response.data.imisNumber)
            }
        }).catch(function (error) {
            commit("setShowLoadingOnApiCall", false);
        })
    },

    async restoreVersionHistory({ commit, rootState, dispatch }, request) {
        commit("setShowLoadingOnApiCall", true);
        await api.restoreVersionHistory(request).then(response => {
            if (response.status == 200 && response.data) {
                commit("setShowLoadingOnApiCall", false);
                dispatch('getVersionHistoryList', response.data.imisNumber);
                dispatch('getOrganizationDetail', response.data.imisNumber);
            }
        }).catch(function (error) {
            commit("setShowLoadingOnApiCall", false);
            Toast.showToast(error?.response?.data, 'E');
        })
    },
    async searchImis({ rootState, dispatch, commit }, imisNumer) {
        commit('setImisSearchResult', null);
        await api.searchImis(imisNumer).then(response => {
            if (response.status == 200 && response.data) {
                commit("setShowLoadingOnApiCall", false);
                commit('setImisSearchResult', JSON.stringify(response.data));
            }
        }).catch(function (error) {
            commit("setShowLoadingOnApiCall", false);
            if (error?.response) {
                commit('setImisSearchResult', "");
                Toast.showToast(error?.response?.data, 'E');
                setTimeout(function () {
                    var imisField = document.getElementById("imisModal").getElementsByTagName("INPUT")[0].focus();
                    if (imisField) {
                        imisField.focus();
                    }
                }, 500);

            }
        })
    },
    async getAPIVersions({ rootState, dispatch, commit }, request) {
        commit("setShowLoadingOnApiCall", true);
        // commit('setAPIVersions', null);
        await api.getAPIVersions(request).then(response => {
            if (response.status == 200 && response.data) {
                commit("setShowLoadingOnApiCall", false);
                // commit('setAPIVersions', JSON.stringify(response.data));

                const commitMappings = {
                    FDX: 'setFdxVersionLookup',
                    DDA: 'setDDAVersions',
                    OFX: 'setOFXVersions',
                    UK: 'setUk',
                    Proprietary: 'setProprietaryVersion',
                  };

                  const commitMutation = commitMappings[request] || 'setAPIVersions';
                  commit(commitMutation, JSON.stringify(response.data));
            }
        }).catch(function (error) {
            commit("setShowLoadingOnApiCall", false);
        })
    },
    async getTaxonomyTypes({ commit }) {
        commit("setTaxonomyTypes", null);
        await api.getTaxonomyTypes().then(response => {
            if (response.status == 200 && response.data) {
                commit("setShowLoadingOnApiCall", false);
                commit("setTaxonomyTypes", JSON.stringify(response.data));
            }
        }).catch(function (error) {
            commit("setShowLoadingOnApiCall", false);
        })
    },

    async getOrganizationsList({ commit, rootState }, currPage) {
        commit('setCompanyDataList', null);
        commit('setShowRREPopup', false);
        commit('setShowREPopup', false);
        commit('setCompanyDataList', null);
        commit('setCompanyDataListCurrPage', 0);
        commit('setCompanyDataListTotalPages', 0);
        commit('setCompanyDataListTotalElements', 0);
        let request = {
            request: {
                sortOrder: rootState.Company.sortOrder ? rootState.Company.sortOrder : "DSC",
                pageNumber: currPage ? currPage : 0,
                recordSize: 7,
                searchText: rootState.Company.searchText ? rootState.Company.searchText : "",
                tagType: rootState.Company.selectedOption ? rootState.Company.selectedOption : "Search by Company",
                countryNames: rootState.Company.countryFilter ? rootState.Company.countryFilter : ["All"],
                publishStatus: rootState.Company.publishFilter ? rootState.Company.publishFilter : "All",
                roles: rootState.Company.roleFilter ? rootState.Company.roleFilter : ["All"],
                memberType: rootState.Company.membershipFilter ? rootState.Company.membershipFilter : ["All"],
                versionNumbers: rootState.Company.fdxVersionFilter ? rootState.Company.fdxVersionFilter : ["All"],
                listingTags:rootState.Company.listingReqTags ? rootState.Company.listingReqTags : null
            },
            user: rootState.Auth.user ? JSON.parse(rootState.Auth.user) : null,
        };

        await api.getOrganizationsList(request).then(response => {
            if (response.status === 200 && response.data.content.length > 0) {
                // for (var i = 0; i < response.data.length; i++) {
                //     if (response.data[i].logoLocation === "" || response.data[i].logoLocation === null) {
                //         response.data[i].logoLocation = defaultLogo;
                //     }
                //     response.data[i].organizationType = orgTypeKeyToVal(rootState.Company.enumTypes, response.data[i].organizationType);

                //     if (JSON.parse(rootState.Auth.user) && response.data[i].imisNumber && JSON.parse(rootState.Auth.user).companyId) {
                //         if (response.data[i].imisNumber.toString() === JSON.parse(rootState.Auth.user).companyId.toString()) {
                //             commit('setShowRREPopup', true);
                //             commit('setShowREPopup', true);
                //         }
                //     }
                //     if (response.data[i].fdxTaxonomyTypeList) {
                //         var taxonomy = [];
                //         for (var ss = 0; ss < response.data[i].fdxTaxonomyTypeList.length; ss++) {
                //             taxonomy.push(orgTypeKeyToVal(rootState.Company.enumTypes, response.data[i].fdxTaxonomyTypeList[ss].type));
                //         }
                //         response.data[i].fdxTaxonomyTypes = taxonomy;
                //     }
                // }
                commit('setCompanyDataList', JSON.stringify(response.data.content));
                commit('setCompanyDataListCurrPage', JSON.stringify(response.data.number+1));
                commit('setCompanyDataListTotalPages', JSON.stringify(response.data.totalPages));
                commit('setCompanyDataListTotalElements', JSON.stringify(response.data.totalElements));
                window.scroll(0,0);
            }
            else{

                    commit('setCompanyDataList', JSON.stringify([]));

            }
        }).catch(function (error) {
            Toast.showToast('Request failed with status code 404', 'E');
        })
    }
}

export default {
    state: vueState,
    getters,
    mutations,
    actions
}