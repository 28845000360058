<template>
  <div>
    <footer role="contentinfo">
      <div class="footer">
        <div class="container">
          <div class="row">
            <div class="footer-col">

              <div class="spread-the-word lgo-wth">
                <img src="https://financialdataexchange.org/images/Home/fdx-footer-logo.png" alt="logo">
                </div>
            </div>
            <div class="footer-col">
              <h2>INFO</h2>
              <ul>
                <li><a :href="this.URL ==='https://registry.financialdataexchange.org'?'https://financialdataexchange.org/FDX/Membership/Join-FDX.aspx':'https://fdxdev.imiscloud.com/FDX/Membership/Benefits.aspx'">Join FDX</a></li>
                <li><a :href="this.URL ==='https://registry.financialdataexchange.org'?'https://financialdataexchange.org/FDX/Contact-Us.aspx':'https://fdxdev.imiscloud.com/FDX/Contact-Us.aspx'">Contact</a></li>
                <li><a :href="this.URL ==='https://registry.financialdataexchange.org'?'https://financialdataexchange.org/FDX/About/FAQs.aspx':'https://fdxdev.imiscloud.com/FDX/About/About-FDX.aspx?WebsiteKey=deae9d6d-1a7a-457b-a678-8a5517f8a474&hkey=dffb9a93-fc7d-4f65-840c-f2cfbe7fe8a6&a315d1c24e44=7#a315d1c24e44'">FAQ</a></li>
                <li><a href="https://www.fsisac.com/responsible-disclosure" class="" target="_blank">Responsible Disclosure</a></li>
              </ul>
            </div>
            <div class="footer-col">
              <h2>LEGAL</h2>
              <ul>
                <li><a :href="this.URL ==='https://registry.financialdataexchange.org'?'https://financialdataexchange.org/common/Uploaded%20files/Policies/FDX%20Privacy%20Policy-(11-13-2019).pdf':'https://fdxdev.imiscloud.com/common/Uploaded%20files/Policies/FDX%20Privacy%20Policy-(11-13-2019).pdf'" target="_blank" rel=noopener aria-label="Privacy link opens in new window">Privacy</a></li>
                <li><a :href="this.URL ==='https://registry.financialdataexchange.org'?'https://financialdataexchange.org/common/Uploaded%20files/Policies/FDX%20API%20License%20Agreement-(11-13-2019).pdf':'https://fdxdev.imiscloud.com/common/Uploaded%20files/Policies/FDX%20API%20License%20Agreement-(11-13-2019).pdf'" target="_blank" rel=noopener aria-label="FDX API link opens in new window">FDX API License Agreement</a></li>
                <li><a :href="this.URL ==='https://registry.financialdataexchange.org'?'https://financialdataexchange.org/common/Uploaded%20files/Policies/FDX%20Website%20-%20Terms%20of%20Use%20-%20TCs%20(11-13-2019).pdf':'https://fdxdev.imiscloud.com/common/Uploaded%20files/Policies/FDX%20Website%20-%20Terms%20of%20Use%20-%20TCs%20(11-13-2019).pdf'" target="_blank" rel=noopener aria-label="Terms of Use link opens in new window">Terms of Use</a></li>
                <li><a :href="this.URL ==='https://registry.financialdataexchange.org'?'https://financialdataexchange.org/common/Uploaded%20files/Policies/FDX%20Legal%20Disclaimer.pdf':'https://fdxdev.imiscloud.com/common/Uploaded%20files/Policies/FDX%20Legal%20Disclaimer.pdf'" target="_blank" rel=noopener aria-label="FDX Legal Disclaimer link opens in new window">FDX Legal Disclaimer</a></li>
                <li><a :href="this.URL ==='https://registry.financialdataexchange.org'?'https://financialdataexchange.org/common/Uploaded%20files/Policies/FDX%20-%20Parent%20-%20Subsidiary%20Membership%20Policy%20[Ratified].pdf':'https://fdxdev.imiscloud.com/common/Uploaded%20files/Policies/FDX%20-%20Parent%20-%20Subsidiary%20Membership%20Policy%20[Ratified].pdf'" target="_blank" rel=noopener aria-label="FDX Parent-Subsidiary Membership Policy link opens in new window">FDX Parent-Subsidiary Membership Policy</a></li>
                <li><a :href="this.URL ==='https://registry.financialdataexchange.org'?'https://financialdataexchange.org/FDX/Legal/Policies.aspx':'https://fdxdev.imiscloud.com/FDX/Legal/Policies.aspx'" >FDX Legal Policies</a></li>
              </ul>
            </div>

            <div class="footer-col">
              <h2>SOCIAL</h2>
              <ul>
                <li>
                  <a href="https://twitter.com/fdxorg" target="_blank" rel=noopener aria-label="Twitter link opens in new window">

                     <img class="TwitterIcon" style="border-width: 0px; margin-right: 10px;width:24px; height:24px; " alt="Twitter icon" src="@/assets/twitter-icon.png">
                     <span class="m-1">Twitter</span>
                    </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/financial-data-exchange" target="_blank" rel=noopener aria-label="Linkedin link opens in new window">
                    <img class="LinkedInIcon" style="border-width: 0px; margin-right: 10px;width:24px; height:24px; " alt="LinkedIn icon" src="@/assets/linkedin-icon.png">
                    <span class="m-1">LinkedIn</span>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UC4C7KRIoFCZxAF8iTI5-wbA" target="_blank" rel=noopener aria-label="Youtube link opens in new window">
                    <img class="vIcon" style="border-width: 0px; margin-right: 10px;width:24px; height:24px; " alt="Youtube icon" src="@/assets/youtube-icon.png">
                    <span class="m-1">YouTube</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-copyright">
        <div class="copyright">
          © 2024 Financial Data Exchange. All rights reserved.
        </div>
      </div>
    </footer>
  </div>
</template>
<script type="text/javascript">
export default {
  name: "Footer",
  data(){
    return{
      URL:window.location.origin,
    }
  }
};
</script>
<style scoped>
body {
  line-height: 1.5;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



.container {
  max-width: 1170px;
  margin: auto;
  text-align: Left;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}
ul {
  list-style: none;
}
.footer {
  color: #fff;
  padding: 70px;
  font-size: 0.875rem;
  bottom: 0;
}
.footer-copyright {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding:25px 0px;

}
.footer-col {
  width: 25%;
  padding: 0 15px;
}
.footer-col h2 {
  font-weight: 700;
  font-style: normal;
  line-height: 1.25;
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
  margin-bottom: 35px;
  position: relative;

}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footer-col ul li a {
  background: transparent;
  color: #fff;
  text-decoration: none;
  font-weight: 600 !important;
  font-size: 14px;
  line-height: 16.41px;
}
.footer-col ul li a:hover {
  color: #f0eaea;

}

.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
}
.footer-col .social-links a:hover {
  background-color: #ffffff;
}

.icon-color {
  color: #1fa885;
  margin-right: 5px;
}
footer {


  background-color:#0f8575!important;
  justify-content: center;
  align-content: center;
}

.lgo-wth img {
  max-width: 130px !important;
}

/*responsive*/
@media (max-width: 768px) {
  .lgo-wth img {
    width: 80% !important;
    max-width: 140px !important;
  }
  .footer-col {
    width: 100%;
    margin-bottom: 30px;
  }
  .footer-copyright{
font-size: 70% !important;

  }
  .footer {
    padding: 30px !important;

  }
}
@media (max-width: 574px) {
  .footer-col {
    width: 100%;
  }
}

</style>
