import Vue from 'vue';
// import VueRouter from 'vue-router';

import { createRouter, createWebHistory } from 'vue-router'

// Vue.use(VueRouter);

const routeOptions = [
    { path: "/", name: "Home", breadcrumb: "Registry Home" },
    { path: "/companyDetail/:id", name: "CompanyDetail", breadcrumb: "Company Detail" },
    { path: "/addCompany", name: "AddCompanyNew", breadcrumb: "Add a new company" },
    { path: "/login", name: "SSO", breadcrumb: "SSO Authentication" },
    { path: "/auth", name: "Auth", breadcrumb: "Authentication" },
    { path: "/logout", name: "Signout", breadcrumb: "Signout" }
]

const routes = routeOptions.map(route => {
    return {
        ...route,
        component: () => import(`@/views/${route.name}.vue`),
    }
})

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
  })
  const app = Vue.createApp({})
  app.use(router)

// const router = new VueRouter({
//     mode: "history",
//     base: process.env.BASE_URL,
//     routes,
//     scrollBehavior() {
//         document.getElementById('app').scrollIntoView();
//     }
// });

export default router;