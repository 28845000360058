// import Vue from 'vue'
import { createApp } from 'vue';
import App from './App.vue'
import router from './router/Router'
import store from './store/Store'
import vBlur from 'v-blur'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

const app = createApp(App);

app.config.productionTip = false
app.use(router);
app.use(store);
app.use(vBlur);
app.use(BootstrapVue);
app.use(IconsPlugin);
app.component("multiselect", VueMultiselect);

app.mount('#app')
